<template>
    <div class="pic-video-box">
        <div class="imgPart"
             v-viewer
             :class="[fjDesc ? '' : 'flex flex-wrap']">
            <div v-for="(item, i) in picList"
                 :key="i + 'pic'"
                 class="picBox flex">
                <el-image :src="item[srcName]"
                          fit="cover"
                          :style="{ width: imgSize, height: imgSize }">
                    <div slot="error"
                         style="width: 100%; height: 100%">
                        <SnNoData :width="noImgSize"
                                  typeNum="1"></SnNoData>
                    </div>
                </el-image>
                <div v-if="fjDesc"
                     class="desc flex-1 pointer scrollbar"
                     :title="item[fjDesc]"
                     :style="{ height: imgSize }">
                    {{ item[fjDesc] }}
                </div>
                <!-- 选择图片 -->
                <div v-if="picCheck"
                     class="check-box"
                     :class="{ checked: item === checked }"
                     @click="checkPic(item)">
                    <i class="fa fa-check"></i>
                </div>
            </div>
        </div>
        <div class="videoPart">
            <div v-for="(item, i) in videoList"
                 :key="i + 'video'"
                 class="videoBox">
                <VueDPlayer :options="{
                        video: {
                            url: item[srcName],
                        },
                        screenshot: true,
                    }"></VueDPlayer>
                <div v-if="fjDesc"
                     class="desc pointer scrollbar"
                     :title="item[fjDesc]">
                    {{ item[fjDesc] }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "pic-video",

    data () {
        return {
            picList: [],
            videoList: [],
            checked: "",
        };
    },
    props: {
        imgSize: {
            type: String,
            default () {
                return "230px";
            },
        },
        videoSize: {
            type: String,
            default () {
                return "230px";
            },
        },
        noImgSize: {
            type: String,
            default () {
                return "150px";
            },
        },
        fjDesc: {
            type: String,
            default () {
                return "";
            },
        },
        picVideoList: {
            default: function () {
                return [];
            },
        },
        srcName: {
            default: function () {
                return "CCLJ";
            },
        },
        picCheck: {
            default: function () {
                return false;
            },
        },
        jztUrl: {
            default: function () {
                return "";
            },
        },
    },
    watch: {
        picVideoList () {
            this.getList();
        },
        jztUrl () {
            this.checked = this.jztUrl;
        },
    },
    mounted () {
        this.getList();
    },
    methods: {
        getList () {
            this.picList = [];
            this.videoList = [];
            this.picVideoList.map((item) => {
                if (item[this.srcName]) {
                    if (this.isVideo(item[this.srcName])) {
                        this.videoList.push(item);
                    } else {
                        this.picList.push(item);
                    }
                }
            });
        },
        isVideo (src) {
            let videoList = ["mp4", "avi", "mov"];
            let type = src.slice(src.length - 3);
            return videoList.indexOf(type.toLowerCase()) > -1;
        },
        checkPic (item) {
            this.checked = this.checked === item ? "" : item[this.srcName];
            this.$emit("update:jztUrl", this.checked);
        },
    },
};
</script>
<style lang="scss" scoped>
.pic-video-box {
    .picBox {
        margin: 0 10px 10px 0;
        position: relative;
        .check-box {
            width: 20px;
            height: 20px;
            line-height: 14px;
            background-color: transparent;
            border: 3px solid #eee;
            border-radius: 50%;
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            box-shadow: 0px 0px 3px #999;
            .fa-check {
                color: transparent;
            }
            &.checked {
                border: 3px solid #fff;
                background-color: #fff;
                .fa-check {
                    color: green;
                }
            }
        }
        .desc {
            margin-left: 10px;
        }
    }
    .videoBox {
        width: 100%;
        margin-bottom: 20px;
        .desc {
            width: 100%;
            margin-top: 10px;
            max-height: 100px;
        }
    }
}
</style>