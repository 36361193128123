<template>
    <div class="form-part scrollbar">
        <el-form
            ref="taskForm"
            class="demo-ruleForm"
            :model="taskForm"
            :label-width="labelWidth"
            @submit.native.prevent
        >
            <el-form-item label="任务名称：">
                <el-input
                    v-model="taskForm.Jcpzfamc"
                    size="mini"
                    style="width: 300px"
                    @change="sendPara()"
                ></el-input>
            </el-form-item>
            <el-form-item label="相关要素：">
                <div>{{ baseInfo.relativeElement }}</div>
            </el-form-item>
            <el-form-item label="情况描述：">
                <div>
                    {{ baseInfo.conditionDesc }}
                </div>
            </el-form-item>

            <el-form-item label="照片：" v-if="baseInfo.picList.length">
                <div class="img-part scrollbar flex flex-wrap">
                    <div
                        v-for="(item, index) in baseInfo.picList"
                        :key="index"
                        class="img-item"
                    >
                        <el-image
                            :src="item.CCLJ"
                            fit="cover"
                            class="pointer"
                            style="width: 100%; height: 100%"
                            v-viewer
                        >
                            <div slot="error" style="width: 100%; height: 100%">
                                <SnNoData width="80px" typeNum="1"></SnNoData>
                            </div>
                        </el-image>
                        <div
                            class="img-btn"
                            :class="{ 'img-active': imgActiveNum == index }"
                            @click="changeImg(item, index)"
                        ></div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="基准照片：">
                <b style="color: red"
                    v-if="baseInfo.picList.length"
                    >请在以上采集的照片中选择或重新上传一张照片作为基准照片</b
                >
                <div v-if="taskForm.Jcwzsytlj" class="pic-box">
                    <el-image v-viewer fit="cover" :src="taskForm.Jcwzsytlj">
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData width="80px" typeNum="1"></SnNoData>
                        </div>
                    </el-image>
                    <div class="del-box" @click="delFile()">
                        <i class="fa fa-remove"></i>
                    </div>
                </div>
                <div v-else>
                    <el-upload
                        :action="`${BASE_URL}UpLoad/FileSave`"
                        accept="image/png,image/jpg,image/jpeg"
                        list-type="picture-card"
                        :file-list="fileList"
                        :limit="1"
                        :class="{
                            noneupload: fileList.length === 1,
                        }"
                        :on-success="handleAvatarSuccess"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </el-form-item>

            <el-form-item label="监测周期：" prop="Zq">
                <div class="flex-y-center">
                    <el-input-number
                        v-model="taskForm.Zq"
                        style="width: 200px; margin-right: 30px"
                        size="mini"
                        @change="sendPara()"
                        :min="1"
                        label="描述文字"
                    ></el-input-number>
                    <el-radio-group
                        v-model="taskForm.Zqdw"
                        class="short-radio"
                        @change="sendPara()"
                    >
                        <el-radio label="3">日</el-radio>
                        <el-radio label="2">周</el-radio>
                        <el-radio label="1">月</el-radio>
                        <el-radio label="0">年</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item class="last-form-item" label="采集人：">
                <SnTreeCjr
                    @handleChange="getCjrItem"
                    ref="SnTreeCjr"
                ></SnTreeCjr>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import picVedio from "../../task_manage/pic_video_show";
export default {
    components: {
        picVedio,
    },
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            taskForm: {
                Jcpzfamc: "", //任务名称
                Jcwzsytlj: "", // 基准照片路径
                Sjcjfzrid: "", //采集人id
                Zq: "", //监测周期
                Zqdw: "", //周期单位
            },
            fileList: [],
            labelWidth: "100px",
            listAry: [],
            imgActiveNum: null,
        };
    },
    props: {
        itemObj: {
            type: Object,
            default() {
                let obj = {
                    Jcwzsytlj: "", // 基准照片路径
                    Jcpzfamc: "", //任务名称
                    Sjcjfzrid: "", //采集人id
                    Zq: "", //监测周期
                    Zqdw: "3", //周期单位
                };
                return obj;
            },
        },
        baseInfo: {
            type: Object,
            default() {
                let obj = {
                    relativeElement: "", //相关要素
                    conditionDesc: "", //情况描述
                    picList: [], //照片地址
                };
                return obj;
            },
        },
    },
    watch: {
        itemObj: {
            immediate: true,
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.taskForm = {
                        ...this.taskForm,
                        ...this.itemObj,
                    };
                    this.$refs.SnTreeCjr.setLabelName(this.taskForm.Sjcjfzrid);
                    this.imgActiveNum = null;
                });
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([]),
        // 上传头像
        handleAvatarSuccess(res, file) {
            this.taskForm.Jcwzsytlj = res.ResultValue[0].FilePath;
            this.sendPara();
        },
        // 切换图片
        changeImg(item, index) {
            this.imgActiveNum = index;
            this.taskForm.Jcwzsytlj = item.CCLJ;
            this.sendPara();
        },
        // 删除附件
        delFile() {
            this.imgActiveNum = null;
            this.taskForm.Jcwzsytlj = "";
            this.sendPara();
        },
        // 筛选采集人信息
        getCjrItem(item) {
            this.taskForm.Sjcjfzrid = item;
            this.sendPara();
        },
        sendPara() {
            this.$emit("getTabItem", this.taskForm);
        },
    },
};
</script>
<style lang="scss" scoped>
.form-part {
    width: 100%;
    // height: 100%;
    .img-part {
        width: calc(100% - 100px);
        height: 120px;
        .img-item {
            width: 120px;
            height: 120px;
            position: relative;
            margin-right: 10px;
            .img-btn {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 20px;
                height: 20px;
                line-height: 14px;
                background-color: transparent;
                border: 3px solid #eee;
                border-radius: 50%;
                cursor: pointer;
                box-shadow: 0px 0px 3px #999;
            }
            .img-active {
                border: 3px solid #186d70;
                background-color: #fff;
            }
        }
    }
    .pic-box {
        width: 120px;
        height: 120px;
        margin: 0 10px 10px 0;
        position: relative;
        .el-image {
            width: 100%;
            height: 100%;
        }
        .del-box {
            width: 20px;
            height: 20px;
            line-height: 14px;
            background-color: #fff;
            border: 3px solid #fff;
            border-radius: 50%;
            position: absolute;
            right: 5px;
            top: 5px;
            text-align: center;
            cursor: pointer;
            .fa-remove {
                color: red;
            }
        }
    }
}
</style>