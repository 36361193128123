<template>
    <div class="tab-pane flex">
        <div class="line"></div>
        <i class="add-btn el-icon-plus" @click="addFormItem()"></i>
        <div class="left-menu scrollbar-hidden">
            <div
                v-for="(item, index) in list"
                :key="index"
                class="left-item"
                :class="{
                    'left-active': index == activeNum
                }"
                @click="changeTask(index)"
            >
                <span class="label">{{
                    item.Jcpzfamc
                }}</span>
                <i
                    class="el-icon-close"
                    @click.stop="deleteFormItem(item, index)"
                ></i>
            </div>
        </div>
        <tabItem
            v-if="list.length"
            :itemObj="itemObj"
            :baseInfo="baseInfo"
            @getTabItem="getTabItem"
        ></tabItem>
        <div v-else class="no-data flex-1">
            <SnNoData :noDataState="true"></SnNoData>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import tabItem from "./tabItem"; //第二个页面的 某一项
export default {
    name: "tab-pane",
    components: {
        tabItem
    },
    props: {
        list: {
            type: Array,
            default() {
                return [];
            }
        },
        baseInfo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            activeNum: 0,
            itemObjTemp: {
                Jcwzsytlj: "", // 基准照片路径
                Jcpzfamc: "", //任务名称
                Sjcjfzrid: "", //采集人id
                Zq: "1", //监测周期
                Zqdw: "3", //周期单位
                qyid: ""
            },
            itemObj: {}
        };
    },
    watch: {
        list: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!val || !val.length) {
                    this.addFormItem();
                } else {
                    this.itemObj = val[this.activeNum];
                }
            }
        }
    },
    mounted() {},
    methods: {
        ...mapActions(["DeleteConfigure"]),
        // 添加一项
        addFormItem() {
            let obj = JSON.parse(JSON.stringify(this.itemObjTemp));
            if (this.baseInfo) {
                obj.Jcpzfamc = this.baseInfo.conditionDesc && this.baseInfo.conditionDesc !== '无' ? this.baseInfo.conditionDesc : this.avoidDuplicateName(1);
            }
            let list = this.list;
            list.push(obj);
            if (list.length == 1) {
                this.activeNum = 0;
            } else {
                this.activeNum = list.length - 1;
            }
            this.itemObj = obj;
        },
        // 避免重名监测点名称
        avoidDuplicateName(num, nameValue = '监测点') {
            let nameList = [];
            this.list.forEach(v => {
                nameList.push(v.Jcpzfamc);
            });
            let name = `${nameValue}${num}`;
            if (!nameList.includes(name)) {
                return name;
            } else {
                let ind = num + 1;
                return this.avoidDuplicateName(ind, nameValue);
            }
        },
        // 当选选中项 赋值
        changeTask(index) {
            this.activeNum = index;
            this.itemObj = this.list[index];
        },
        // 删除一项
        deleteFormItem(item, index) {
            if (!item.qyid) {
                this.list.splice(index, 1);
                let newIndex = 0;
                if (index - 1 && this.list[index - 1]) {
                    newIndex = index - 1;
                }
                this.changeTask(newIndex);
                return;
            }
            this.$confirm("是否永久删除该条记录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteItem(item, index);
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info");
                });
        },
        async deleteItem(item, index) {
            let result = await this.DeleteConfigure({
                jcpzid: item.qyid
            });
            if (result.IsSuccess) {
                this.list.splice(index, 1);
                let newIndex = 0;
                if (index - 1 && this.list[index - 1]) {
                    newIndex = index - 1;
                }
                this.changeTask(newIndex);
                this.common.showMsg("删除成功", "success");
            } else {
                this.common.showMsg("删除失败", "error");
            }
        },
        getTabItem(item) {
            this.itemObj = item;
            this.$set(this.list, this.activeNum, item);
            this.$emit('update:list', this.list);
        }
    }
};
</script>

<style lang="scss" scoped>
.tab-pane {
    max-height: 490px;
    .add-btn {
        width: 28px;
        color: #186d70;
        font-size: 16px;
        text-align: center;
        font-weight: 700;
        border-bottom: 1px solid #d4ddec;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 6px;
        padding-bottom: 10px;
    }
    .line {
        width: 4px;
        height: calc(100% - 70px);
        display: block;
        position: absolute;
        top: 0;
        left: 36px;
        background-color: #186d70;
    }
    .left-menu {
        margin-top: 30px;
        width: 40px;
        // height: 500px;
        position: relative;
        padding-left: 6px;
        .left-item {
            width: 30px;
            min-height: 130px;
            line-height: 30px;
            text-align: center;
            writing-mode: tb-rl;
            color: #333335;
            position: relative;
            cursor: pointer;
            padding: 14px 0 24px 0;
            &.left-active {
                color: #fff;
                background-image: url("~@image/monitoring_cloud/leftNameBg1.png"), url("~@image/monitoring_cloud/leftNameBg3.png"), url("~@image/monitoring_cloud/leftNameBg2.png");
                background-repeat: no-repeat;
                background-position: top, 0px 20px, bottom;
                background-size: auto, auto calc(100% - 40px), auto;
                .el-icon-close {
                    display: block;
                    position: absolute;
                    bottom: 10px;
                    right: 50%;
                    transform: translateX(50%);
                    font-weight: 700;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .el-icon-close {
                display: none;
            }
        }
    }
}
</style>