<template>
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :label-width="labelWidth"
        class="demo-ruleForm"
        @submit.native.prevent
    >
        <el-row>
            <el-col :span="11">
                <el-form-item label="遗产要素：">
                    <div :title="ruleForm.MC" class="rule-value">
                        {{ ruleForm.MC || "无" }}
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="异常类型：">
                    <div class="rule-value">
                        {{ ruleForm.YCLX_DESC }}
                    </div>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="11">
                <el-form-item label="异常位置：">
                    <div class="rule-value" :title="ruleForm.YCFSWZ">
                        {{ ruleForm.YCFSWZ }}
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="评 估：">
                    <div class="rule-value">{{ ruleForm.PG_DESC }}</div>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="11">
                <el-form-item label="上报时间：">
                    <div class="rule-value">{{ ruleForm.CJSJ }}</div>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item label="上报人员：">
                    <div class="rule-value">{{ ruleForm.NAME }}</div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item label="上报人电话：">
                    <div :title="ruleForm.SBRDH" class="rule-value">
                        {{ ruleForm.SBRDH }}
                    </div>
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item label="情况描述：">
            <div class="conditionPart flex-between">
                <div class="desc">{{ ruleForm.SJMS }}</div>
                <!-- YCSFJC 0 未解除 1 已解除 2 误报 -->
                <img class="img" :src="getStateImg(ruleForm.YCSFJC)" />
            </div>
        </el-form-item>
        <el-form-item label="采取的措施：">
            <div class="actPart">
                {{ ruleForm.DSCQDCS }}
            </div>
        </el-form-item>

        <el-form-item label="照 片：">
            <div class="scrollbar">
                <picVedio :picVideoList="zpOptions" :srcName="'SYT'"></picVedio>
            </div>
        </el-form-item>
        <el-form-item label="处置措施：">
            <div class="flex czcsPart">
                <div class="title ellipsis ellipsis1" :title="ruleForm.GCMC">
                    {{ ruleForm.GCMC }}
                </div>
                <div
                    v-if="ruleForm.GCMC"
                    class="detail pointer"
                    @click="
                        engineeringRecordPart.dialog =
                            !engineeringRecordPart.dialog
                    "
                >
                    详情>>
                </div>
            </div>
        </el-form-item>

        <div
            class="bhzzyhjzzhcjlPart scrollbar"
            v-if="engineeringRecordPart.dialog"
        >
            <engineeringRecord
                :detail="engineeringRecordPart"
            ></engineeringRecord>
        </div>
    </el-form>
</template>
<script>
import { mapActions } from "vuex";
import picVedio from "../../task_manage/pic_video_show";
import engineeringRecord from "@views/working_desk/basic_data/table_type/2_protect_manage/engineering_record";
export default {
    components: {
        picVedio,
        engineeringRecord,
    },
    props: {
        ruleForm: Object,
        zpOptions: Array,
        engineeringRecordPart: Object,
    },
    data() {
        return {
            labelWidth: "100px",
        };
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        // 获取预警状态图片
        getStateImg(num) {
            let img = "";
            switch (num) {
                case "0": //未解除
                    img = require("@image/monitoring_cloud/yj_wjc.png");
                    break;
                case "1": //已解除
                    img = require("@image/monitoring_cloud/yj_yjc.png");
                    break;
                case "2": //误报
                    img = require("@image/monitoring_cloud/yj_wb.png");
                    break;
            }
            return img;
        },
    },
};
</script>
<style lang="scss" scoped>
.conditionPart {
    position: relative;
    .img {
        position: absolute;
        right: 0;
    }
}
.rule-value {
    border-bottom: 1px solid #797979;
}
</style>