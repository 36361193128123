<template>
    <div class="errorDialogPart scrollbar">
        <!-- 查看预警详情 -->
        <template v-if="isFirstShow">
            <!-- 表单部分 -->
            <tableList
                :ruleForm="ruleForm"
                :zpOptions="zpOptions"
                :engineeringRecordPart="engineeringRecordPart"
            ></tableList>
            <!-- 操作按钮 -->
            <div class="footer flex-end flex-y-center">
                <div
                    class="errorState btn"
                    v-if="getBtnState()"
                    @click="handleErrorWarning()"
                >
                    误报
                </div>
                <!-- 未解除预警 -->
                <div
                    class="errorState btn"
                    @click="getRemoveWarn()"
                    v-if="ruleForm.YCSFJC != 1 && ruleForm.YCSFJC != 2"
                >
                    关闭预警
                </div>
                <div
                    class="normalState btn"
                    v-if="getBtnState()"
                    @click="getToTaskDetails()"
                >
                    转为任务
                </div>
                <div
                    class="normalState btn"
                    v-if="getCheckBtnState()"
                    @click="getToTaskDetails()"
                >
                    查看任务
                </div>
            </div>
        </template>
        <!-- 转为任务 -->
        <template v-else>
            <el-tabs v-model="tabPart.activeNum">
                <el-tab-pane
                    v-for="(item, index) in tabPart.list"
                    :key="index"
                    :name="item.name"
                    :label="item.name"
                    class="tab-item"
                >
                    <tabPane
                        :list.sync="item.list"
                        :baseInfo="baseInfo"
                    ></tabPane>
                    <div class="footer flex-end">
                        <div
                            class="errorState btn"
                            @click="getRemoveWarn()"
                            v-if="
                                routeName == 'taskExecution' &&
                                ruleForm.YCSFJC == '0'
                            "
                        >
                            关闭预警
                        </div>
                        <div
                            v-if="btnItemShow"
                            class="normalState btn"
                            @click="saveTask()"
                        >
                            确定
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </template>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import tableList from "./tableList"; //第一个页面的表单
import tabPane from "./tabPane"; //第一个页面的表单
export default {
    components: {
        tableList,
        tabPane,
    },
    data() {
        return {
            //保护展示与环境整治工程记录信息
            engineeringRecordPart: {
                dialog: false,
                type: 2,
                id: "",
                version: "",
                fid: "",
                ItemID: "24",
                basicType: "document",
                ytID: "RWID",
            },
            BASE_URL: window.REQUEST_URL,
            DOWN_URL: window.DOWN_URL,
            ruleForm: {
                YCSFZZ: "", //预警发生 状态  0否 1 是
                YCSFJC: "", //预警解除 状态 0 未解除 1 已解除 2 误报
            },
            rules: {
                JCZQ: [
                    {
                        required: true,
                        message: "请输入监测周期",
                        trigger: "blur",
                    },
                ],
            },
            zpOptions: [],
            isFirstShow: true,
            labelWidth: "100px",
            routeName: "", //路由名称
            baseInfo: {
                relativeElement: "", //相关要素
                conditionDesc: "", //情况描述
                picList: [], //照片地址
            },
            tabPart: {
                activeNum: "异常预警跟踪任务",
                list: [
                    {
                        name: "异常预警跟踪任务",
                        list: [],
                    },
                    {
                        name: "异常预警处置任务",
                        list: [],
                    },
                ],
            },
        };
    },
    props: {
        dialogInfo: Object,
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            },
        },
        dialogDetail: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        dialogInfo: {
            deep: true,
            immediate: true,
            handler() {
                this.isFirstShow = true;
                this.engineeringRecordPart.dialog = false;
                if (this.dialogInfo && this.dialogInfo.ID) {
                    this.routeName = this.$route.name;
                    this.getTableInfo();
                }
            },
        },
        dialogDetail: {
            deep: true,
            immediate: true,
            handler(newVal) {
                if (newVal.dialog && newVal.title === "异常预警") {
                    this.isFirstShow = true;
                    this.tabPart.activeNum = "异常预警跟踪任务";
                }
            },
        },
    },
    methods: {
        ...mapActions([
            "GetRcxcycjlDetail", // 异常预警详情
            "GetYcjlToTaskDetails", // 异常预警任务详情页
            "GetRcxcycjlRemoveWarn", // 异常预警解除服务
            "UpdateEarlywarning", // 异常预警误报服务
            "AddYcjlToTask", // 异常预警任务保存服务
            "getCjrList", // 根据移动端权限获取用户
        ]),
        // 异常类型列表
        async getTableInfo() {
            this.isFirstShow = true;
            let result = await this.GetRcxcycjlDetail({
                ycjlid: this.dialogInfo.ID,
            });
            if (result && result.length > 0) {
                this.ruleForm = result[0];
                this.zpOptions = result[0].PIC || [];
                this.engineeringRecordPart.id = this.ruleForm.BHGCID;
            }
        },
        // 异常预警任务详情页
        async getToTaskDetails() {
            this.isFirstShow = false;
            // 弹框标题
            this.$emit("changeTitle", "异常处置、跟踪配置");
            let result = await this.GetYcjlToTaskDetails({
                ycjlid: this.dialogInfo.ID,
                qyid: this.dialogInfo.QYID,
            });

            if (
                result.ycjldic &&
                result.ycjldic.length &&
                result.ycjldic[0].PIC
            ) {
                this.zpOptions = result.ycjldic[0].PIC;
            } else {
                this.zpOptions = [];
            }
            // 配置信息
            this.tabPart.list[0].list = result.ycgzModel;
            this.tabPart.list[1].list = result.ycczModel;
            //基本信息
            if (result.ycjldic.length) {
                this.baseInfo.relativeElement = result.ycjldic[0].MC; //相关要素
                this.baseInfo.conditionDesc = result.ycjldic[0].SJMS; //情况描述
                if (result.ycjldic[0].PIC.length) {
                    this.baseInfo.picList = result.ycjldic[0].PIC; //照片地址
                } else {
                    this.baseInfo.picList = []; //照片地址
                }
            } else {
                this.baseInfo.relativeElement = ""; //相关要素
                this.baseInfo.conditionDesc = ""; //情况描述
                this.baseInfo.picList = []; //照片地址
            }
        },
        // 保存任务
        async saveTask() {
            // 监测配置字符串(周期，采集人，周期单位)、
            let list = [];

            this.tabPart.list.map((item, index) => {
                item.list.map((item1) => {
                    item1.dwlx = index;
                    item1.Rwsfbhdqzq = 1;
                    item1.id = item1.qyid ? item1.qyid : ""; // 区域id;
                    list.push(item1);
                });
            });
            let result = await this.AddYcjlToTask({
                ycjlid: this.dialogInfo.ID,
                jcpz: JSON.stringify(list),
            });
            if (result.IsSuccess) {
                this.common.showMsg(result.ResultValue, "success");
                this.$emit("closeDialog");
            } else {
                this.common.showMsg("配置保存失败！", "error");
            }
        },
        // 解除预警
        async getRemoveWarn() {
            let result = await this.GetRcxcycjlRemoveWarn({
                ycjlid: this.dialogInfo.ID,
            });
            if (result.IsSuccess) {
                this.common.showMsg(result.ResultValue, "success");
                this.$emit("closeDialog", false);
            } else {
                this.common.showMsg("解除预警失败", "error");
            }
        },
        // 误报
        async handleErrorWarning() {
            let result = await this.UpdateEarlywarning({
                ycjlid: this.dialogInfo.ID,
            });
            if (result.IsSuccess) {
                this.common.showMsg("误报成功", "success");
                this.$emit("closeDialog", false);
            } else {
                this.common.showMsg("误报失败", "error");
            }
        },
        // 误报按钮
        getBtnState() {
            let state = false;
            // 预警 未解除 已发生 未处置
            if (
                this.ruleForm.YCSFJC !== "1" &&
                this.ruleForm.YCSFJC !== "2" &&
                this.ruleForm.YCSFZZ !== "1"
            ) {
                state = true;
            }
            return state;
        },
        // 查看任务按钮
        getCheckBtnState() {
            let state = false;
            // 预警 未解除 已发生 已处置
            if (
                this.ruleForm.YCSFJC !== "1" &&
                this.ruleForm.YCSFJC !== "2" &&
                this.ruleForm.YCSFZZ === "1"
            ) {
                state = true;
            }
            return state;
        },
        // 打开
        openBhzsyhjzzPart() {
            const { href } = this.$router.resolve({
                name: "engineeringRecords",
                query: {
                    type: 2,
                    id: this.ruleForm.BHGCID,
                    version: "",
                    fid: "",
                    ItemID: "24",
                    basicType: "document",
                    ytID: "RWID",
                },
            });
            window.open(href, "_blank");
        },
    },
};
</script>
<style lang="scss" scoped>
.errorDialogPart {
    width: 100%;
    max-height: 620px;
    .tab-item {
        width: 100%;
        height: 100%;
        position: relative;
        .no-data {
            position: relative;
        }
    }

    .footer {
        width: 100%;
        height: 50px;
        margin-top: 20px;
        border-top: 1px dashed #bacbdf;

        .btn {
            min-width: 75px;
            height: 30px;
            line-height: 10px;
            margin: 5px 0 5px 10px;
            padding: 9px 15px;
            border-radius: 20px;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
        }
        .errorState {
            background: rgb(255, 144, 101);
            color: rgb(255, 255, 255);
            border-color: rgb(255, 144, 101);
            box-shadow: rgb(255 144 101) 0px 1px 6px;
        }
        .normalState {
            background: rgb(24, 109, 112);
            color: rgb(255, 255, 255);
            border-color: rgb(24, 109, 112);
            box-shadow: rgb(24 109 112) 0px 1px 6px;
        }
    }
}
</style>